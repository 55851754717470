import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const hofbrauhausCustomerGroup = 'hofbrauhaus';
export const customerGroup = hofbrauhausCustomerGroup;

const supportEmail = {
	hofbrauhaus: 'hofbrauhaus@smoothr.de'
};
const firebaseConfig = {
	hofbrauhaus: {
		apiKey: "AIzaSyDctbzSoLPuCJG9Vvm-1ncsTipJTNKl-sg",
		authDomain: "hofbrauhaus-web-app.firebaseapp.com",
		projectId: "hofbrauhaus-web-app",
		storageBucket: "hofbrauhaus-web-app.appspot.com",
		messagingSenderId: "659880940065",
		appId: "1:659880940065:web:76207a9fa2fd62bffcc960",
		measurementId: "G-2M4WYHSWGT"
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyB_HPdZlrsLWkJK2n7inyRM0ClYFhPeXgs',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};
