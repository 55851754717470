import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const hofbrauhausCustomerGroup = 'hofbrauhaus';
export const customerGroup = hofbrauhausCustomerGroup;

const supportEmail = {
	hofbrauhaus: 'hofbrauhaus@smoothr.de'
};
const firebaseConfig = {
	hofbrauhaus: {
		apiKey: "AIzaSyDctbzSoLPuCJG9Vvm-1ncsTipJTNKl-sg",
		authDomain: "hofbrauhaus-web-app.firebaseapp.com",
		projectId: "hofbrauhaus-web-app",
		storageBucket: "hofbrauhaus-web-app.appspot.com",
		messagingSenderId: "659880940065",
		appId: "1:659880940065:web:f911703be2d77558fcc960",
		measurementId: "G-0TB2T7KP3M"
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDC0aYRLnuWNhvHeoeR8WTwqa_8YanX-nM',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};

import 'zone.js/dist/zone-error';
